/** @type {Record<string, DeviceExploitAvailabilitiesData>} */
export default {
  "HE_DTV_WT1H_AFAAABAA": {
    "nvm": {}
  },
  "HE_DTV_WT1H_AFAAATAA": {
    "nvm": {}
  },
  "HE_DTV_WT1H_AFAAJAAA": {
    "nvm": {}
  },
  "HE_DTV_WT1M_AFAAABAA": {
    "nvm": {}
  },
  "HE_DTV_WT1M_AFAAATAA": {
    "nvm": {}
  },
  "HE_DTV_WT1M_AFAAJAAA": {
    "nvm": {}
  },
  "HE_DTV_W15A_AFADABAA": {
    "nvm": {}
  },
  "HE_DTV_W15A_AFADATAA": {
    "nvm": {}
  },
  "HE_DTV_W15B_AFADABAA": {
    "nvm": {}
  },
  "HE_DTV_W15H_AFADABAA": {
    "nvm": {}
  },
  "HE_DTV_W15H_AFADATAA": {
    "nvm": {}
  },
  "HE_DTV_W15L_AFAAABAA": {
    "nvm": {}
  },
  "HE_DTV_W15M_AFAAABAA": {
    "nvm": {}
  },
  "HE_DTV_W15M_AFAAATAA": {
    "nvm": {}
  },
  "HE_DTV_W15M_AFAAJAAA": {
    "nvm": {}
  },
  "HE_DTV_W15U_AFADABAA": {
    "nvm": {}
  },
  "HE_DTV_W15U_AFADATAA": {
    "nvm": {}
  },
  "HE_DTV_W16K_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.35",
        "release": "3.4.0-5711",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.40",
        "release": "3.4.2-570910",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16K_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.06",
        "release": "3.4.0-5706",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16K_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.30.04",
        "release": "3.3.3-4304",
        "codename": "dreadlocks-dharug"
      },
      "patched": {
        "version": "05.70.45",
        "release": "3.4.2-570911",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16M_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.65.03",
        "release": "3.4.1-610703",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.30",
        "release": "3.4.2-610708",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16M_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.05",
        "release": "3.4.0-6105",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16M_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.05",
        "release": "3.4.0-6105",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.35",
        "release": "3.4.2-610709",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16N_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.30",
        "release": "3.4.0-5909",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.30",
        "release": "3.4.2-590808",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16N_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.15",
        "release": "3.4.0-5906",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16N_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.30.02",
        "release": "3.3.3-4202",
        "codename": "dreadlocks-dharug"
      },
      "patched": {
        "version": "05.70.35",
        "release": "3.4.2-590809",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16P_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.20",
        "release": "3.4.0-5806",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.30",
        "release": "3.4.2-580508",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16P_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.03",
        "release": "3.4.0-5803",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16P_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.10",
        "release": "3.4.0-5804",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16R_AFAAABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.15",
        "release": "3.4.0-6206",
        "codename": "dreadlocks-digya"
      },
      "patched": {
        "version": "05.70.35",
        "release": "3.4.2-6210",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_DTV_W16R_AFAAATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "05.60.04",
        "release": "3.4.0-6204",
        "codename": "dreadlocks-digya"
      }
    }
  },
  "HE_PJT_W16Z_AAAAAIAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.17",
        "release": "3.3.0-1238",
        "codename": "dreadlocks-deua"
      }
    }
  },
  "HE_DTV_W17H_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.30",
        "release": "3.9.0-62710",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.30",
        "release": "3.9.2-6270908",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.45",
        "release": "3.9.2-6270911",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17H_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.05",
        "release": "3.9.0-62705",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.00.05",
        "release": "3.9.0-62705",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17H_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.10",
        "release": "3.9.0-62706",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.35",
        "release": "3.9.2-6270909",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.45",
        "release": "3.9.2-6270911",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17M_AFADATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.05.10",
        "release": "3.9.1-6280404",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.30",
        "release": "3.9.2-6280408",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.40",
        "release": "3.9.2-6280410",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17P_AFADABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.25",
        "release": "3.9.0-62907",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.45",
        "release": "3.9.2-62911",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.50",
        "release": "3.9.2-62912",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17P_AFADJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.15",
        "release": "3.9.0-62905",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.55",
        "release": "3.9.2-62913",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.55",
        "release": "3.9.2-62913",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17R_AFAAABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.15",
        "release": "3.9.0-63006",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.35",
        "release": "3.9.2-63010",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.35",
        "release": "3.9.2-63010",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_DTV_W17R_AFAAATAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "06.00.04",
        "release": "3.9.0-63004",
        "codename": "dreadlocks2-dudhwa"
      },
      "patched": {
        "version": "06.10.35",
        "release": "3.9.2-63010",
        "codename": "dreadlocks2-dudhwa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "06.10.35",
        "release": "3.9.2-63010",
        "codename": "dreadlocks2-dudhwa"
      }
    }
  },
  "HE_PJT_W17V_AAAAGLAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.05",
        "release": "3.7.0-6005",
        "codename": "dreadlocks2-drto"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.05",
        "release": "3.7.0-6005",
        "codename": "dreadlocks2-drto"
      }
    }
  },
  "HE_PJT_W17W_AAAAAAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.11",
        "release": "3.7.0-3011",
        "codename": "dreadlocks2-drto"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.11",
        "release": "3.7.0-3011",
        "codename": "dreadlocks2-drto"
      }
    }
  },
  "HE_PJT_W17W_AAAAABAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.05",
        "release": "3.7.0-3005",
        "codename": "dreadlocks2-drto"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.05",
        "release": "3.7.0-3005",
        "codename": "dreadlocks2-drto"
      }
    }
  },
  "HE_PJT_W17W_AAAAAIAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.12",
        "release": "3.7.0-3012",
        "codename": "dreadlocks2-drto"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.12",
        "release": "3.7.0-3012",
        "codename": "dreadlocks2-drto"
      }
    }
  },
  "HE_PJT_W17W_AAAAJAAA": {
    "nvm": {},
    "rootmytv": {
      "latest": {
        "version": "03.00.03",
        "release": "3.7.0-3003",
        "codename": "dreadlocks2-drto"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.03",
        "release": "3.7.0-3003",
        "codename": "dreadlocks2-drto"
      }
    }
  },
  "HE_DTV_W18A_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.40.26",
        "release": "4.4.0-12",
        "codename": "goldilocks-gorce"
      },
      "patched": {
        "version": "05.50.10",
        "release": "4.4.2-10",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18A_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.30.10",
        "release": "4.3.0-9703",
        "codename": "goldilocks-goobang"
      },
      "patched": {
        "version": "05.50.10",
        "release": "4.4.2-10",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18H_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.40.26",
        "release": "4.4.0-12",
        "codename": "goldilocks-gorce"
      },
      "patched": {
        "version": "05.50.01",
        "release": "4.4.2-1",
        "codename": "goldilocks-gorce"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "patched": {
        "version": "03.00.61",
        "release": "4.0.0-1522102",
        "codename": "goldilocks-gayasan"
      },
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18H_AFADJAAA": {
    "rootmytv": {
      "latest": {
        "version": "05.10.10",
        "release": "4.1.3-5523",
        "codename": "goldilocks-glacier"
      },
      "patched": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18M_AFADJAAA": {
    "rootmytv": {
      "latest": {
        "version": "05.10.10",
        "release": "4.1.3-5814",
        "codename": "goldilocks-glacier"
      },
      "patched": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.30",
        "release": "4.4.2-14",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.30",
        "release": "4.4.2-14",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.30",
        "release": "4.4.2-14",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18O_AFABABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.40.26",
        "release": "4.4.0-12",
        "codename": "goldilocks-gorce"
      },
      "patched": {
        "version": "05.50.06",
        "release": "4.4.2-6",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18O_AFABATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.30.10",
        "release": "4.3.0-9804",
        "codename": "goldilocks-goobang"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.10",
        "release": "4.3.0-9804",
        "codename": "goldilocks-goobang"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.10",
        "release": "4.3.0-9804",
        "codename": "goldilocks-goobang"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.30.10",
        "release": "4.3.0-9804",
        "codename": "goldilocks-goobang"
      }
    }
  },
  "HE_DTV_W18O_AFABJAAA": {
    "rootmytv": {
      "latest": {
        "version": "05.10.03",
        "release": "4.1.3-5620",
        "codename": "goldilocks-glacier"
      },
      "patched": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18R_AFAAABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.40.26",
        "release": "4.4.0-12",
        "codename": "goldilocks-gorce"
      },
      "patched": {
        "version": "05.50.10",
        "release": "4.4.2-10",
        "codename": "goldilocks-gorongosa"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "asm": {
      "patched": {
        "version": "03.00.63",
        "release": "4.0.0-1531004",
        "codename": "goldilocks-gayasan"
      },
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.50.15",
        "release": "4.4.2-11",
        "codename": "goldilocks-gorongosa"
      }
    }
  },
  "HE_DTV_W18R_AFAAATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.30.11",
        "release": "4.3.0-1000301",
        "codename": "goldilocks-goobang"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.11",
        "release": "4.3.0-1000301",
        "codename": "goldilocks-goobang"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.11",
        "release": "4.3.0-1000301",
        "codename": "goldilocks-goobang"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.30.11",
        "release": "4.3.0-1000301",
        "codename": "goldilocks-goobang"
      }
    }
  },
  "HE_PJT_W18Z_AAAAAAAA": {
    "rootmytv": {
      "latest": {
        "version": "03.00.11",
        "release": "4.1.0-1311",
        "codename": "goldilocks-glacier"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.00.11",
        "release": "4.1.0-1311",
        "codename": "goldilocks-glacier"
      }
    },
    "asm": {
      "latest": {
        "version": "03.00.11",
        "release": "4.1.0-1311",
        "codename": "goldilocks-glacier"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.11",
        "release": "4.1.0-1311",
        "codename": "goldilocks-glacier"
      }
    }
  },
  "HE_PJT_W18Z_AAAAABAA": {
    "rootmytv": {
      "latest": {
        "version": "03.00.08",
        "release": "4.1.0-2012",
        "codename": "goldilocks-glacier"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.00.08",
        "release": "4.1.0-2012",
        "codename": "goldilocks-glacier"
      }
    },
    "asm": {
      "latest": {
        "version": "03.00.08",
        "release": "4.1.0-2012",
        "codename": "goldilocks-glacier"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.08",
        "release": "4.1.0-2012",
        "codename": "goldilocks-glacier"
      }
    }
  },
  "HE_PJT_W18Z_AAAAJAAA": {
    "rootmytv": {
      "latest": {
        "version": "03.00.04",
        "release": "4.1.0-3004",
        "codename": "goldilocks-glacier"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.00.04",
        "release": "4.1.0-3004",
        "codename": "goldilocks-glacier"
      }
    },
    "asm": {
      "latest": {
        "version": "03.00.04",
        "release": "4.1.0-3004",
        "codename": "goldilocks-glacier"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.00.04",
        "release": "4.1.0-3004",
        "codename": "goldilocks-glacier"
      }
    }
  },
  "HE_DTV_W19H_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.20.06",
        "release": "4.9.5-6",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.20.15",
        "release": "4.9.5-10",
        "codename": "goldilocks2-gympie"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19H_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.25.08",
        "release": "4.9.6-108",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.30.15",
        "release": "4.9.7-12",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19H_AFADJAAA": {
    "rootmytv": {
      "latest": {
        "version": "05.20.06",
        "release": "4.9.5-6",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.30.15",
        "release": "4.9.7-12",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19O_AFABABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.20.09",
        "release": "4.9.5-9",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.20.15",
        "release": "4.9.5-10",
        "codename": "goldilocks2-gympie"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19O_AFABATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.25.09",
        "release": "4.9.6-109",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.30.11",
        "release": "4.9.7-11",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19O_AFABJAAA": {
    "rootmytv": {
      "latest": {
        "version": "05.00.03",
        "release": "4.9.0-53403",
        "codename": "goldilocks2-gyeryongsan"
      },
      "patched": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.30.45",
        "release": "4.9.7-18",
        "codename": "goldilocks2-gyoen"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19P_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.20.09",
        "release": "4.9.5-9",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.20.15",
        "release": "4.9.5-10",
        "codename": "goldilocks2-gympie"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19P_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.25.09",
        "release": "4.9.6-109",
        "codename": "goldilocks2-gympie"
      },
      "patched": {
        "version": "05.30.20",
        "release": "4.9.7-13",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.40.10",
        "release": "4.10.0-10",
        "codename": "goldilocks2-grampians"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19R_AFAAABAA": {
    "rootmytv": {
      "latest": {
        "version": "05.00.30",
        "release": "4.9.1-54309",
        "codename": "goldilocks2-gyeryongsan"
      },
      "patched": {
        "version": "05.00.35",
        "release": "4.9.1-54310",
        "codename": "goldilocks2-gyeryongsan"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_DTV_W19R_AFAAATAA": {
    "rootmytv": {
      "latest": {
        "version": "05.00.03",
        "release": "4.9.1-54303",
        "codename": "goldilocks2-gyeryongsan"
      },
      "patched": {
        "version": "05.30.11",
        "release": "4.9.7-11",
        "codename": "goldilocks2-gyoen"
      }
    },
    "crashd": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "asm": {
      "latest": {
        "version": "05.30.40",
        "release": "4.9.7-17",
        "codename": "goldilocks2-gyoen"
      },
      "patched": {
        "version": "05.40.20",
        "release": "4.10.0-12",
        "codename": "goldilocks2-grampians"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "05.40.45",
        "release": "4.10.0-17",
        "codename": "goldilocks2-grampians"
      }
    }
  },
  "HE_PJT_W19Z_AAAAAAAA": {
    "rootmytv": {
      "latest": {
        "version": "04.00.06",
        "release": "4.7.0-7006",
        "codename": "goldilocks2-gulaga"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.00.06",
        "release": "4.7.0-7006",
        "codename": "goldilocks2-gulaga"
      }
    },
    "asm": {
      "latest": {
        "version": "04.00.06",
        "release": "4.7.0-7006",
        "codename": "goldilocks2-gulaga"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.00.06",
        "release": "4.7.0-7006",
        "codename": "goldilocks2-gulaga"
      }
    }
  },
  "HE_DTV_C20P_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.85",
        "release": "5.3.1-28",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.40.09",
        "release": "5.4.1-9",
        "codename": "jhericurl-jebil"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_C20P_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.40",
        "release": "5.3.0-19",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.40.70",
        "release": "5.4.2-25",
        "codename": "jhericurl-jebil"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.60",
        "release": "5.5.0-13",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.61",
        "release": "5.5.0-1301",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20H_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.57",
        "release": "5.3.0-2202",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.30.59",
        "release": "5.3.0-2205",
        "codename": "jhericurl-jasper"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20H_AFADJAAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.10",
        "release": "5.3.0-10",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20L_AFAAJAAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.11",
        "release": "5.3.0-11",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20O_AFABABAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.58",
        "release": "5.3.0-2204",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.30.61",
        "release": "5.3.0-2206",
        "codename": "jhericurl-jasper"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20O_AFABATAA": {
    "rootmytv": {
      "latest": {
        "version": "04.35.10",
        "release": "5.3.2-2204",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.35.20",
        "release": "5.3.2-2206",
        "codename": "jhericurl-jasper"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20O_AFABJAAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.10",
        "release": "5.3.0-10",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20P_AFADABAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.58",
        "release": "5.3.0-2204",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.30.62",
        "release": "5.3.0-2209",
        "codename": "jhericurl-jasper"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20P_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.11",
        "release": "5.3.0-11",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.40.70",
        "release": "5.4.2-25",
        "codename": "jhericurl-jebil"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_W20P_AFADJAAA": {
    "rootmytv": {
      "latest": {
        "version": "04.30.11",
        "release": "5.3.0-11",
        "codename": "jhericurl-jasper"
      },
      "patched": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      }
    },
    "crashd": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "wta": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "asm": {
      "latest": {
        "version": "04.50.53",
        "release": "5.5.0-1103",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.50.56",
        "release": "5.5.0-1107",
        "codename": "jhericurl-jervisbay"
      },
      "patched": {
        "version": "04.50.90",
        "release": "5.5.0-19",
        "codename": "jhericurl-jervisbay"
      }
    }
  },
  "HE_DTV_N21D_AFAAATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.43.11",
        "release": "6.4.1-11",
        "codename": "kisscurl-kluane"
      }
    },
    "crashd": {
      "patched": {
        "version": "03.43.11",
        "release": "6.4.1-11",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "patched": {
        "version": "03.43.11",
        "release": "6.4.1-11",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "patched": {
        "version": "03.43.11",
        "release": "6.4.1-11",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.43.11",
        "release": "6.4.1-11",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21A_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "03.21.40",
        "release": "6.2.0-39",
        "codename": "kisscurl-kavir"
      },
      "patched": {
        "version": "03.30.10",
        "release": "6.3.0-8",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.35",
        "release": "6.3.3-439",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.82",
        "release": "6.4.0-1703",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21K_AFADATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.87",
        "release": "6.4.0-1804",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21K_AFADJAAA": {
    "rootmytv": {
      "patched": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.87",
        "release": "6.4.0-1804",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21O_AFABATAA": {
    "rootmytv": {
      "latest": {
        "version": "03.21.20",
        "release": "6.2.0-35",
        "codename": "kisscurl-kavir"
      },
      "patched": {
        "version": "03.30.06",
        "release": "6.3.0-6",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.36.50",
        "release": "6.3.3-442",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.85",
        "release": "6.4.0-18",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.87",
        "release": "6.4.0-1804",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21P_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "03.11.03",
        "release": "6.0.1-454603",
        "codename": "kisscurl-kalaupapa"
      },
      "patched": {
        "version": "03.33.27",
        "release": "6.3.2-41702",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.45",
        "release": "6.3.3-441",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.82",
        "release": "6.4.0-1703",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_W21U_AFADATAA": {
    "rootmytv": {
      "latest": {
        "version": "03.21.40",
        "release": "6.2.0-39",
        "codename": "kisscurl-kavir"
      },
      "patched": {
        "version": "03.30.10",
        "release": "6.3.0-8",
        "codename": "kisscurl-kinglake"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "wta": {
      "latest": {
        "version": "03.36.30",
        "release": "6.3.3-438",
        "codename": "kisscurl-kinglake"
      },
      "patched": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      }
    },
    "asm": {
      "latest": {
        "version": "03.40.70",
        "release": "6.4.0-15",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.40.80",
        "release": "6.4.0-17",
        "codename": "kisscurl-kluane"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.40.82",
        "release": "6.4.0-1703",
        "codename": "kisscurl-kluane"
      },
      "patched": {
        "version": "03.41.00",
        "release": "6.4.0-21",
        "codename": "kisscurl-kluane"
      }
    }
  },
  "HE_DTV_C22H_AFABATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "wta": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "asm": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.41.40",
        "release": "7.4.0-29",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.42",
        "release": "7.4.0-2902",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_C22L_AFAAATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "wta": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "asm": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.41.46",
        "release": "7.4.0-3001",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.52",
        "release": "7.4.0-3006",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_C22P_AFADATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.30.12",
        "release": "7.3.0-12",
        "codename": "mullet-mebbin"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "wta": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "asm": {
      "latest": {
        "version": "03.34.35",
        "release": "7.3.2-18",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "03.34.40",
        "release": "7.3.2-19",
        "codename": "mullet-mebbin"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.41.30",
        "release": "7.4.0-27",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.32",
        "release": "7.4.0-2702",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W22A_AFADATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.21.25",
        "release": "7.2.0-43",
        "codename": "mullet-marine"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.34.00",
        "release": "7.3.1-46",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.41.37",
        "release": "7.4.0-2802",
        "codename": "mullet-meru"
      }
    },
    "wta": {
      "latest": {
        "version": "03.34.00",
        "release": "7.3.1-46",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.41.37",
        "release": "7.4.0-2802",
        "codename": "mullet-meru"
      }
    },
    "asm": {
      "latest": {
        "version": "03.34.00",
        "release": "7.3.1-46",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.41.37",
        "release": "7.4.0-2802",
        "codename": "mullet-meru"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.41.37",
        "release": "7.4.0-2802",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.38",
        "release": "7.4.0-2803",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W22H_AFABATPU": {
    "crashd": {
      "patched": {
        "version": "13.30.60",
        "release": "8.3.0-24",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "patched": {
        "version": "13.30.60",
        "release": "8.3.0-24",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "patched": {
        "version": "13.30.60",
        "release": "8.3.0-24",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "13.30.61",
        "release": "8.3.0-2401",
        "codename": "number1-nameri"
      }
    }
  },
  "HE_DTV_W22H_AFADATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.10.70",
        "release": "7.1.0-50",
        "codename": "mullet-maria"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.75",
        "release": "7.4.0-16",
        "codename": "mullet-meru"
      }
    },
    "wta": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.75",
        "release": "7.4.0-16",
        "codename": "mullet-meru"
      }
    },
    "asm": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.75",
        "release": "7.4.0-16",
        "codename": "mullet-meru"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.40.94",
        "release": "7.4.0-190708",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.03",
        "release": "7.4.0-1913",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W22L_AFAAATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.21.15",
        "release": "7.2.0-41",
        "codename": "mullet-marine"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "wta": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "asm": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.40.91",
        "release": "7.4.0-1901",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.03",
        "release": "7.4.0-1913",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W22O_AFABATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.11.35",
        "release": "7.1.0-63",
        "codename": "mullet-maria"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.33.90",
        "release": "7.3.1-44",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "wta": {
      "latest": {
        "version": "03.33.90",
        "release": "7.3.1-44",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "asm": {
      "latest": {
        "version": "03.33.90",
        "release": "7.3.1-44",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.90",
        "release": "7.4.0-19",
        "codename": "mullet-meru"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.40.93",
        "release": "7.4.0-1905",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W22O_AFABATPU": {
    "crashd": {
      "patched": {
        "version": "13.30.55",
        "release": "8.3.0-23",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "patched": {
        "version": "13.30.55",
        "release": "8.3.0-23",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "patched": {
        "version": "13.30.55",
        "release": "8.3.0-23",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "13.30.56",
        "release": "8.3.0-2301",
        "codename": "number1-nameri"
      }
    }
  },
  "HE_DTV_W22P_AFADATAA": {
    "rootmytv": {
      "patched": {
        "version": "03.21.20",
        "release": "7.2.0-42",
        "codename": "mullet-marine"
      }
    },
    "crashd": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.85",
        "release": "7.4.0-18",
        "codename": "mullet-meru"
      }
    },
    "wta": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.85",
        "release": "7.4.0-18",
        "codename": "mullet-meru"
      }
    },
    "asm": {
      "latest": {
        "version": "03.33.85",
        "release": "7.3.1-43",
        "codename": "mullet-mebbin"
      },
      "patched": {
        "version": "04.40.85",
        "release": "7.4.0-18",
        "codename": "mullet-meru"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "04.41.02",
        "release": "7.4.0-1912",
        "codename": "mullet-meru"
      },
      "patched": {
        "version": "04.41.03",
        "release": "7.4.0-1913",
        "codename": "mullet-meru"
      }
    }
  },
  "HE_DTV_W23A_AFADATAA": {
    "crashd": {
      "latest": {
        "version": "03.20.55",
        "release": "8.2.0-20",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.20.61",
        "release": "8.2.0-2103",
        "codename": "number1-nambung"
      }
    },
    "wta": {
      "latest": {
        "version": "03.20.55",
        "release": "8.2.0-20",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.20.61",
        "release": "8.2.0-2103",
        "codename": "number1-nambung"
      }
    },
    "asm": {
      "latest": {
        "version": "03.20.55",
        "release": "8.2.0-20",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.20.61",
        "release": "8.2.0-2103",
        "codename": "number1-nambung"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.31.60",
        "release": "8.3.1-33",
        "codename": "number1-nameri"
      },
      "patched": {
        "version": "03.31.82",
        "release": "8.3.1-3607",
        "codename": "number1-nameri"
      }
    }
  },
  "HE_DTV_W23H_AFADATAA": {
    "crashd": {
      "latest": {
        "version": "03.20.50",
        "release": "8.2.0-19",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.77",
        "release": "8.3.0-1602",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "latest": {
        "version": "03.20.50",
        "release": "8.2.0-19",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.77",
        "release": "8.3.0-1602",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "latest": {
        "version": "03.20.50",
        "release": "8.2.0-19",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.77",
        "release": "8.3.0-1602",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.31.55",
        "release": "8.3.1-32",
        "codename": "number1-nameri"
      },
      "patched": {
        "version": "03.20.50",
        "release": "8.2.0-19",
        "codename": "number1-nambung"
      }
    }
  },
  "HE_DTV_W23M_AFADATAA": {
    "crashd": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.85",
        "release": "8.3.0-18",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.85",
        "release": "8.3.0-18",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.85",
        "release": "8.3.0-18",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.31.75",
        "release": "8.3.1-36",
        "codename": "number1-nameri"
      }
    }
  },
  "HE_DTV_W23O_AFABATAA": {
    "crashd": {
      "latest": {
        "version": "03.20.17",
        "release": "8.2.0-710",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.60",
        "release": "8.3.0-13",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "latest": {
        "version": "03.20.17",
        "release": "8.2.0-710",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.60",
        "release": "8.3.0-13",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "latest": {
        "version": "03.20.17",
        "release": "8.2.0-710",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.60",
        "release": "8.3.0-13",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.31.45",
        "release": "8.3.1-30",
        "codename": "number1-nameri"
      },
      "patched": {
        "version": "03.31.82",
        "release": "8.3.1-3607",
        "codename": "number1-nameri"
      }
    }
  },
  "HE_DTV_W23P_AFADATAA": {
    "crashd": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.76",
        "release": "8.3.0-1601",
        "codename": "number1-nameri"
      }
    },
    "wta": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.76",
        "release": "8.3.0-1601",
        "codename": "number1-nameri"
      }
    },
    "asm": {
      "latest": {
        "version": "03.20.40",
        "release": "8.2.0-17",
        "codename": "number1-nambung"
      },
      "patched": {
        "version": "03.30.76",
        "release": "8.3.0-1601",
        "codename": "number1-nameri"
      }
    },
    "dejavuln": {
      "latest": {
        "version": "03.31.55",
        "release": "8.3.1-32",
        "codename": "number1-nameri"
      },
      "patched": {
        "version": "03.31.76",
        "release": "8.3.1-3601",
        "codename": "number1-nameri"
      }
    }
  }
}